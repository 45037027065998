import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal, DatePicker} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import {API, useFind, backend} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Input, Select} from 'components/form/types'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import utils from '@eitje/utils'
import {ConfirmButton} from 'components/ui'
import {useTable} from 'components/general/table'
import moment, {makeRange} from 'initializers/moment'
import {dateInRange} from 'selectors/records'
import {DropdownPicker} from '@eitje/form-fields-web'
import {Form} from 'initializers/form'
import {InfoTooltip} from 'components/ui'

export const statusses = [
  'paid',
  'disregard',
  'pending',
  'in_transaction',
  'chargeback',
  'failed',
  'zero_ignore',
  'missing_mandate',
  'ING',
  'external',
]

const fields = [
  'id',
  'amt',
  'total_amt',
  'status',
  'org_name',
  'env_names',
  'amt_users',
  'sent_time',
  'billing_date',
  'formatted_invoice_number',
  'manual_invoicable?',
  'info',
  'emails',
  'current_reminder_nr',
  'tiers',
  'staffel_amt',
  'price_reduction',
  'pdf',
  'resend_email',
  'regenerate',
]
const noTotalFields = ['env_names', 'emails']
const renderCell = (item, field, idx) => {
  const val = _.get(item, field)
  if (noTotalFields.includes(field) && !item.id) return ' '
  switch (field) {
    case 'total_amt':
      return <b> {val} </b>
    case 'status':
      if (!item.id) return null
      return <Status {...item} />
    case 'pdf':
      return <DownloadButton {...item} />
    case 'info':
      return <Info {...item} />
    case 'resend_email':
      return <ResendEmail {...item} />
    case 'regenerate':
      return <Regenerate {...item} />
    default:
      return null
  }
}

const Regenerate = ({id}) => {
  return <ConfirmButton onClick={() => backend.post(`billing_invoices/${id}/regenerate`)}> genereer PDF opnieuw </ConfirmButton>
}

const ResendEmail = ({id}) => {
  return <ConfirmButton onClick={() => backend.post(`billing_invoices/${id}/send_mail`)}> stuur opnieuw </ConfirmButton>
}

const Info = ({billing_info_id}) => {
  const info = useFind('billingInfos', billing_info_id)
  return <p> {info.company_name} </p>
}

const searchProps = {searchField: ['env_names', 'emails', 'formatted_invoice_number']}

const useInvoiceTable = items => {
  const tableData = useTable({items, showTotals: false, showIndex: true, searchProps, fields, renderCell, name: 'invoiceTable'})
  return tableData
}

const DownloadButton = ({pdf}) => {
  return (
    <a href={pdf} target="_blank">
      <Button> download </Button>
    </a>
  )
}

const Status = item => {
  const submitForm = data => API.update('billing_invoices', data)
  return (
    <Form initialValues={item} onSubmit={submitForm}>
      <DropdownPicker submitStrategy="change" field="status" onChange={val => console.log(val)} items={statusses} />
    </Form>
  )
}

export default useInvoiceTable
