import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, DatePicker, Select} from 'components/form/types'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {useToggleState} from '@eitje/react-hooks'
import {Switch} from '@eitje/form-fields-web'
import {getRich} from 'actions/billing'
import useAll from 'hooks/use_all'
import utils from '@eitje/web_utils'
import {API} from '@eitje/easy_api'
import {store} from 'index'
import useAsyncEffect from 'hooks/use_async_effect'
import useSearch from 'hooks/use_search'

const Run = () => {
  useAsyncEffect(async () => {
    await API.index('pending_invoices', {refresh: true})
  }, [])
  const invoices = _.orderBy(useAll('pendingInvoices'), 'org_name')
  const totalMoney = utils.reduce(invoices.map(i => Number(i.amt))).toFixed(2)
  const totalPriceRed = utils.reduce(invoices.map(i => Number(i.price_reduction))).toFixed(2)
  const allIds = invoices.map(i => i.id)
  const {filteredItems, searchInput} = useSearch(invoices, {searchField: 'org_name'})
  if (invoices.length === 0) return <p style={{marginTop: 60}}> No invoices (yet) ... </p>
  const example = invoices[0]

  return (
    <div style={{marginTop: 80}}>
      {searchInput}
      <h3> Loonrun voor: {moment(example).format('MMM - YYYY')}, alleen mollie klanten </h3>
      <h3>{invoices.length} invoices</h3>
      <h3>
        ${totalMoney} omzet | ${totalPriceRed} korting
      </h3>

      {filteredItems.map(i => (
        <Invoice item={i} />
      ))}
      <Button onClick={() => getRich({allIds})}> get rich </Button>
    </div>
  )
}

const Invoice = ({item}) => {
  return (
    <div className="fRow" style={{marginBottom: 10}}>
      <p style={{marginRight: 5}}>| name: {item.org_name} </p>
      <p style={{marginRight: 5}}>| amt: {item.amt} </p>
      <p style={{marginRight: 5}}>| korting: {item.price_reduction} </p>
      <p style={{marginRight: 5}}>| staffel: {item.staffel_amt} </p>
    </div>
  )
}

export default Run
