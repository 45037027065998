import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal, DatePicker} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import {API, useWhere} from '@eitje/easy_api'
import {Switch} from '@eitje/form-fields-web'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, Select} from 'components/form/types'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import utils from '@eitje/utils'
import {useTable} from 'components/general/table'
import moment, {makeRange} from 'initializers/moment'
import {dateInRange} from 'selectors/records'
import useInvoiceTable from './use_invoice_table'
import {useToggleState} from '@eitje/react-hooks'
import Dropdown from 'components/dropdown'
import {LinkButton} from 'components/routing'

const statusses = ['pending', 'in_transaction', 'chargeback', 'failed', 'missing_mandate']

const Page = () => {
  useEffect(() => {
    API.index('billing_invoices', {filters: {status: statusses}})
  }, [])
  const invoices = useWhere('billingInvoices', {status: statusses})
  let items = invoices

  const {table, searchInput, columnPicker} = useInvoiceTable(items)
  return (
    <div>
      <LinkButton text="all" to="/billing/invoices" />
      {searchInput}
      {columnPicker}

      {table}
      <div style={{height: 100}} />
      <Footer invoices={items} />
    </div>
  )
}

const Footer = ({invoices}) => {
  const openAmt = utils.reduce(invoices.filter(i => i.status != 'paid').map(i => Number(i.amt))).toFixed(2)
  return (
    <div style={{backgroundColor: 'white', width: '100%', position: 'fixed', bottom: 0}}>
      <p> Totaal openstaand: {openAmt} </p>
    </div>
  )
}

export default Page
