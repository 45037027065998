import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal, DatePicker} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import {API} from '@eitje/easy_api'
import {Switch} from '@eitje/form-fields-web'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, Select} from 'components/form/types'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import utils from '@eitje/utils'
import {useTable} from 'components/general/table'
import moment, {makeRange} from 'initializers/moment'
import {dateInRange} from 'selectors/records'
import useInvoiceTable from './use_invoice_table'
import {useToggleState} from '@eitje/react-hooks'
import {statusses} from './use_invoice_table'
import Dropdown from 'components/dropdown'
import {LinkButton} from 'components/routing'

const {MonthPicker} = DatePicker
export const getYear = date => moment(date).year()

export const getMonth = date => moment(date).month() + 1 // moment thinks january == 0

export const makeDateObj = date => {
  return {month: getMonth(date), year: getYear(date)}
}

const Page = () => {
  const [statusFilter, setStatusFilter] = useToggleState([])
  const [date, setDate] = useState(moment().subtract(1, 'month'))
  const invoiceDate = date.clone().subtract(1, 'month') // we want to show invoices of the previous month (invoices of last month are paid in this month)
  const {year, month} = makeDateObj(invoiceDate)

  useEffect(() => {
    API.index('billing_invoices', {filters: {year, month}})
  }, [year, month])
  const range = invoiceDate.range('month')
  const invoices = useSelector(state =>
    dateInRange(state, 'billingInvoices', {start_date: range.start, end_date: range.end}, {field: 'billing_date'}),
  )
  let items = invoices

  if (utils.exists(statusFilter)) {
    items = invoices.filter(i => statusFilter.includes(i.status))
  }

  const {table, searchInput, columnPicker} = useInvoiceTable(items)
  return (
    <div>
      <LinkButton text="unpaid" to="/billing/invoices/unpaid" />
      {searchInput}
      <FilterDropdown onChange={setStatusFilter} value={statusFilter} />
      {columnPicker}
      <MonthPicker format="MMM YYYY" value={date} onChange={date => setDate(date)} />

      {table}
      <div style={{height: 100}} />
      <Footer invoices={items} />
    </div>
  )
}

const Footer = ({invoices}) => {
  invoices = invoices.filter(i => i.status != 'disregard')
  const amt = utils.reduce(invoices.map(i => Number(i.amt))).toFixed(2)
  const openAmt = utils.reduce(invoices.filter(i => i.status != 'paid').map(i => Number(i.amt))).toFixed(2)
  return (
    <div style={{backgroundColor: 'white', width: '100%', position: 'fixed', bottom: 0}}>
      <p> Omzet: {amt} </p>
      <p> Totaal openstaand: {openAmt} </p>
    </div>
  )
}

const FilterDropdown = ({onChange, value}) => {
  const elements = statusses.map(s => <StatusSwitch status={s} value={value.includes(s)} onChange={() => onChange(s)} />)
  return (
    <Dropdown visibleAfterClick elements={elements}>
      <Button> filters kiezen ({value.length}) </Button>
    </Dropdown>
  )
}

const StatusSwitch = ({status, value, onChange}) => {
  return (
    <div>
      <p> status: {status} </p>
      <Switch value={value} onChange={onChange} />
    </div>
  )
}

export default Page
